'use client'

import { createContext, useContext } from 'react'

import {
  type ExperimentType,
  type TenantBasedExperiment,
} from '../model/Experiment.types'

export type ExperimentContextType = {
  /**
   * Accepts single or multiple experiments and manages their activation
   */
  addExperimentKeysForActivation: (
    keys: TenantBasedExperiment | string | (TenantBasedExperiment | string)[],
  ) => void

  /**
   * Experiments currently running in Optimizely
   * https://app.optimizely.com/v2/projects/17522040231/flags/list
   */
  experiments: ExperimentType[]
}

export const ExperimentContext = createContext<ExperimentContextType>({
  addExperimentKeysForActivation: () => {
    throw new Error('Wrap the component in <ExperimentsContextProvider />')
  },
  experiments: [],
})

export const useExperimentsContext = (): ExperimentContextType =>
  useContext(ExperimentContext)
