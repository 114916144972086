import {
  type PolymorphicComponent,
  type PolymorphicProps,
  useButton,
  useSlotProps,
} from '@mui/base'
import { clsx } from 'clsx'
import {
  type ElementType,
  type ForwardedRef,
  forwardRef,
  type MouseEventHandler,
  type ReactNode,
  useCallback,
  useContext,
} from 'react'

import { IconCaretDown } from '@redteclab/icons'

import { AccordionContext } from './AccordionContext'

type AccordionSummaryOwnProps = {
  /**
   * The icon to display as the expand indicator
   * @default <IconCaretDown />
   */
  expandIcon?: ReactNode

  slots?: {
    root?: ElementType
  }
}

interface AccordionSummaryTypeMap<
  RootComponentType extends ElementType = 'div',
> {
  defaultComponent: RootComponentType
  props: AccordionSummaryOwnProps
}

type AccordionSummaryProps<
  RootComponentType extends
    ElementType = AccordionSummaryTypeMap['defaultComponent'],
> = PolymorphicProps<
  AccordionSummaryTypeMap<RootComponentType>,
  RootComponentType
>

export const AccordionSummary = forwardRef(function AccordionSummary<
  RootComponentType extends ElementType = 'div',
>(
  props: AccordionSummaryProps<RootComponentType>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const {
    children,
    expandIcon = <IconCaretDown />,
    onClick: onClickProp,
    slots,
    ...other
  } = props

  const { expanded, toggle } = useContext(AccordionContext)

  const onClick: MouseEventHandler = useCallback(
    (event) => {
      toggle(event)

      // TypeScript is not able to infer onClick from generic `RootComponentType`
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      onClickProp?.(event)
    },
    [onClickProp, toggle],
  )

  const Root = slots?.root ?? 'div'

  const { getRootProps } = useButton({
    ...props,
    onClick,
    ref,
    rootElementName: Root,
    tabIndex: 0,
  })

  const rootProps = useSlotProps({
    additionalProps: {
      'aria-expanded': expanded,
      ref,
    },
    className: clsx(
      'accordion-summary',
      expanded && 'accordion-summary_expanded',
    ),
    elementType: Root,
    externalForwardedProps: other,
    externalSlotProps: undefined,
    getSlotProps: getRootProps,
    ownerState: undefined,
  })

  return (
    <Root {...rootProps}>
      <div className="accordion-summary__content">{children}</div>
      {expandIcon ? (
        <div
          className={clsx(
            'accordion-summary__expand-icon-wrapper',
            expanded && 'accordion-summary__expand-icon-wrapper_expanded',
          )}
        >
          {expandIcon}
        </div>
      ) : null}
    </Root>
  )
}) as PolymorphicComponent<AccordionSummaryTypeMap>
