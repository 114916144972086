'use client'

import { type SelectListboxSlotProps as MuiSelectListboxSlotProps } from '@mui/base'
import { clsx } from 'clsx'
import {
  forwardRef,
  type MouseEventHandler,
  type ReactNode,
  useCallback,
  useRef,
} from 'react'

import { IconClose } from '@redteclab/icons'

type ListboxProps = MuiSelectListboxSlotProps<
  Record<string, unknown>,
  false
> & { title?: ReactNode }

export const Listbox = forwardRef<HTMLUListElement, ListboxProps>(
  function Listbox(props, forwardedRef) {
    const {
      children,
      className,
      onBlur,
      onKeyDown,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- variable is not needed for rendering
      ownerState,
      title,
      ...rest
    } = props

    const onClose: MouseEventHandler<HTMLButtonElement> = useCallback(
      (event) => {
        // @ts-expect-error -- emulate listbox close behaviour
        onBlur(event)
      },
      [onBlur],
    )

    const ref = useRef<HTMLDivElement>(null)

    return (
      <div
        className="select-listbox"
        ref={ref}
        // Make element focusable to handle `onBlur` event on `ul` element
        tabIndex={-1}
      >
        <header className="select-listbox__header">
          {title ? (
            <span className="select-listbox__title">{title}</span>
          ) : null}
          <button
            className="select-listbox__close-button"
            onClick={onClose}
            type="button"
          >
            <IconClose className="select-listbox__close-button-icon" />
          </button>
        </header>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions -- required for custom navigation logic */}
        <ul
          className={clsx('select-listbox__list', className)}
          onBlur={(event) => {
            /*
             * Prevent popup close on clicking on custom listbox wrapper
             * Source https://github.com/mui/material-ui/blob/6fa088cb591117f48c27fb5824af2d8c988e21c0/packages/mui-base/src/useList/useList.ts#L305-L308
             */
            if (ref.current?.contains(event.relatedTarget)) {
              // focus remains within the list
              return
            }

            onBlur(event)
          }}
          onKeyDown={(event) => {
            /*
             * Select focuses the first or selected option in a list on open.
             * Prevent `Tab` functionality to avoid focus leaking from the list
             * to browser controls (the list is attached to body element).
             * The list has its own arrow buttons handlers for navigation.
             */
            if (event.key === 'Tab') {
              event.preventDefault()
            }

            onKeyDown(event)
          }}
          ref={forwardedRef}
          {...rest}
        >
          {children}
        </ul>
      </div>
    )
  },
)
