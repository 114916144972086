import { TENANT } from '../../tenant/tenantTypes'

import {
  EXPERIMENT_NAME_AD_SERVER,
  EXPERIMENT_NAME_AD_SERVER_USE_GLOO_API,
  EXPERIMENT_NAME_ERX_ADYEN_MP,
  EXPERIMENT_NAME_RELEASE_SWITCH_TOGGLE,
  EXPERIMENT_NAME_RELEASE_TOGGLE_VARIANT_MARKETPLACE,
  EXPERIMENT_NAME_RELEASE_TOGGLE_VARIANT_NOW,
  EXPERIMENT_NAME_RELEASE_TOGGLE_VARIANT_SAE_ONLY,
} from './EXPERIMENT_NAME'
import { EXPERIMENT_EXIT_INTENT_POPUP } from './EXPERIMENT_NAME_PER_TENANT'
import { EXPERIMENT_VARIATION } from './EXPERIMENT_VARIATION'
import {
  type ExperimentType,
  type TenantBasedExperiment,
} from './Experiment.types'

const experimentVariations = Object.values(EXPERIMENT_VARIATION)

export const experimentAccessorIsValidExperimentVariation = (
  value: string | null | undefined,
): value is EXPERIMENT_VARIATION =>
  Boolean(value) && experimentVariations.includes(value as EXPERIMENT_VARIATION)

const experimentAccessorIsEnabled = (
  key: ExperimentType['name'] | undefined,
  experiments: ExperimentType[] = [],
): boolean => {
  if (!key) {
    return false
  }
  const experiment = experiments.find((exp) => exp.name === key)

  return experiment?.isEnabled ?? false
}

export const experimentAccessorIsEnabledAndVariant = ({
  experiments = [],
  forceDesktop = false,
  key,
  variation,
}: {
  experiments: ExperimentType[]
  forceDesktop: boolean
  key: ExperimentType['name'] | undefined
  variation: EXPERIMENT_VARIATION
}): boolean => {
  if (!key) {
    return false
  }
  let experimentName = key

  let experiment = experiments.find((exp) => exp.name === experimentName)
  /*
   * For desktop is the experiment name f.e.: cro-666. For mobile version it will be
   * cro-666-m. On the server side we have no opportunity to see if it is mobile browser
   * or not, mobile is always set to false, hence we have a name per tenant only for a desktop.
   * But the data in server page properties we need also for using during mobile experiment.
   */
  if (!experiment && forceDesktop) {
    experimentName = `${key}-m`
    experiment = experiments.find((exp) => exp.name === experimentName)
  }

  return (experiment?.isEnabled && experiment.variant === variation) ?? false
}

const experimentAccessorIsVariableTrue = (
  key: ExperimentType['name'],
  variable: string,
  experiments: ExperimentType[] = [],
): boolean => {
  const experiment = experiments.find((exp) => exp.name === key)
  if (!experiment?.isEnabled) {
    return false
  }

  return Boolean(experiment.variables[variable])
}

export const experimentAccessorFilterAndGetExperiments = (
  names: ExperimentType['name'][] = [],
  experiments: ExperimentType[] = [],
): ExperimentType[] => experiments.filter(({ name }) => names.includes(name))

const marketplaceTenants = new Set([
  TENANT.AT,
  TENANT.BE,
  TENANT.COM,
  TENANT.IT,
])

export const experimentAccessorIsMarketplace = (
  experiments: ExperimentType[],
  tenant: TENANT,
): boolean => {
  /*
   * On hard cached pages experiments are not provided (cookie cut by Varnish).
   * Check marketplace enabled tenants first.
   */
  if (marketplaceTenants.has(tenant)) {
    return true
  }

  return experimentAccessorIsVariableTrue(
    EXPERIMENT_NAME_RELEASE_SWITCH_TOGGLE,
    EXPERIMENT_NAME_RELEASE_TOGGLE_VARIANT_MARKETPLACE,
    experiments,
  )
}

export const experimentAccessorGetExperimentNamePerTenant = ({
  experimentName,
  isMobile,
  tenant,
}: {
  experimentName: TenantBasedExperiment
  isMobile?: boolean
  tenant: TENANT
}): ExperimentType['name'] | undefined => {
  const experimentTenantName = experimentName[tenant]
  if (!experimentTenantName) {
    return undefined
  }
  if (typeof experimentTenantName === 'string') {
    return isMobile ? `${experimentTenantName}-m` : experimentTenantName
  }

  return experimentTenantName[isMobile ? 'mobile' : 'desktop']
}

export const experimentAccessorIsVariantV1 = (
  experiment: ExperimentType,
): boolean => {
  return Boolean(
    experiment.isEnabled && experiment.variant === EXPERIMENT_VARIATION.V1,
  )
}

export const experimentAccessorIsSaeOnly = (
  experiments: ExperimentType[] = [],
): boolean =>
  experimentAccessorIsVariableTrue(
    EXPERIMENT_NAME_RELEASE_SWITCH_TOGGLE,
    EXPERIMENT_NAME_RELEASE_TOGGLE_VARIANT_SAE_ONLY,
    experiments,
  )

export const experimentAccessorIsErxAdyenMpEnabled = (
  experiments: ExperimentType[],
): boolean => {
  return experimentAccessorIsEnabled(EXPERIMENT_NAME_ERX_ADYEN_MP, experiments)
}
export const experimentAccessorGetExperimentData = (
  experiments: ExperimentType[],
  experimentName: string,
): ExperimentType | undefined => {
  const experiment = experiments.find((exp) => exp.name === experimentName)

  return experiment ?? undefined
}

export const experimentAccessorIsNowReleaseEnabled = (
  experiments: ExperimentType[],
): boolean => {
  return experimentAccessorIsVariableTrue(
    EXPERIMENT_NAME_RELEASE_SWITCH_TOGGLE,
    EXPERIMENT_NAME_RELEASE_TOGGLE_VARIANT_NOW,
    experiments,
  )
}

export const experimentAccessorNonDefault = (
  experimentType: ExperimentType,
): boolean => {
  return Boolean(
    experimentType.isEnabled &&
      experimentType.variant !== EXPERIMENT_VARIATION.DEFAULT,
  )
}

export const experimentAccessorGetExitIntentPopupModalExperiment = (
  experiments: ExperimentType[],
  tenant: TENANT,
  isMobile: boolean,
): ExperimentType | undefined => {
  const exitIntentPopupModalExperimentName =
    experimentAccessorGetExperimentNamePerTenant({
      experimentName: EXPERIMENT_EXIT_INTENT_POPUP,
      isMobile,
      tenant,
    })

  if (!exitIntentPopupModalExperimentName) {
    return undefined
  }

  return experimentAccessorGetExperimentData(
    experiments,
    exitIntentPopupModalExperimentName,
  )
}

export const experimentAccessorIsAdServerEnabled = (
  experiments: ExperimentType[],
): boolean => {
  const experiment = experiments.find(
    (exp) => exp.name === EXPERIMENT_NAME_AD_SERVER,
  )

  if (!experiment) {
    return false
  }

  const { isEnabled, variant }: ExperimentType = experiment

  return Boolean(
    isEnabled &&
      (variant === EXPERIMENT_VARIATION.KEVEL ||
        variant === EXPERIMENT_VARIATION.V1),
  )
}

export const experimentAccessorIsAdServerUseGlooApiEnabled = (
  experiments: ExperimentType[],
): boolean => {
  return experimentAccessorIsEnabled(
    EXPERIMENT_NAME_AD_SERVER_USE_GLOO_API,
    experiments,
  )
}
