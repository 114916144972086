'use client'

import {
  type PolymorphicComponent,
  type PolymorphicProps,
  useSlotProps,
} from '@mui/base'
import { unstable_useControlled as useControlled } from '@mui/utils'
import { clsx } from 'clsx'
import {
  Children,
  type ElementType,
  type ForwardedRef,
  forwardRef,
  type ReactNode,
  type RefObject,
  type SyntheticEvent,
  type TransitionEventHandler,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { AccordionContext } from './AccordionContext'

/**
 * Manages collapse/expand animation
 */
const useCollapsed = (
  expanded: boolean,
): {
  collapsed: boolean
  transitionProps: {
    onTransitionEnd: TransitionEventHandler<HTMLDivElement>
    ref: RefObject<HTMLDivElement | null>
  }
} => {
  const ref = useRef<HTMLDivElement>(null)

  const onTransitionEnd: TransitionEventHandler<HTMLDivElement> = useCallback(
    (event): void => {
      /**
       * When the "expand" transition ends, we need to reset the (max) height of the
       * component in order for it to allow additional/dynamic content that might
       * be loaded after that.
       *
       * When the "collapse" transition end, we remove inline styling for CSS class.
       */
      event.currentTarget.style.maxHeight = ''
    },
    [],
  )

  const [delayedExpanded, setDelayedExpanded] = useState(expanded)

  const delayedExpandedRef = useRef(delayedExpanded)
  delayedExpandedRef.current = delayedExpanded

  useLayoutEffect(() => {
    /**
     * Workaround to skip initial `expanded` state handling
     */
    if (delayedExpandedRef.current === expanded) {
      return
    }

    const transitionElement = ref.current
    let requestId = 0

    if (transitionElement) {
      /**
       * Set `maxHeight`: for expanding - to set target height, for collapsing -
       * to define starting height.
       *
       * Always set the maxHeight to the actual (scroll) height because the transition will not
       * animate without explicit values (maxHeight="none" does not animate to maxHeight="0px").
       */
      transitionElement.style.maxHeight = `${transitionElement.scrollHeight}px`

      if (!expanded) {
        /**
         * After initial height is set above, tell the browser target height for collapse
         */
        requestId = window.requestAnimationFrame(() => {
          transitionElement.style.maxHeight = '0px'
        })
      }
    }

    setDelayedExpanded(expanded)

    return (): void => {
      if (requestId) {
        window.cancelAnimationFrame(requestId)
      }
    }
  }, [expanded])

  return {
    collapsed: !delayedExpanded,
    transitionProps: {
      onTransitionEnd,
      ref,
    },
  }
}

type AccordionOwnProps = {
  /**
   * First element should be `AccordionSummary`
   */
  children: ReactNode

  /**
   * If `true`, expands the accordion by default
   * @default false
   */
  defaultExpanded?: boolean

  /**
   * If `true`, expands the accordion, otherwise collapse it. Setting this prop enables control over the accordion.
   */
  expanded?: boolean

  /**
   * Callback fired when the expand/collapse state is changed.
   *
   * @param event The event source of the callback. **Warning**: This is a generic event not a change event.
   * @param expanded The `expanded` state of the accordion.
   */
  onChange?: (event: SyntheticEvent, expanded: boolean) => void

  slots?: {
    root?: ElementType
  }
}

interface AccordionTypeMap<RootComponentType extends ElementType = 'div'> {
  defaultComponent: RootComponentType
  props: AccordionOwnProps
}

export type AccordionProps<
  RootComponentType extends ElementType = AccordionTypeMap['defaultComponent'],
> = PolymorphicProps<AccordionTypeMap<RootComponentType>, RootComponentType>

/**
 * The accordion component allows the user to show and hide sections of related content on a page
 */
export const Accordion = forwardRef<HTMLDivElement, AccordionProps>(
  function Accordion<RootComponentType extends ElementType = 'div'>(
    props: AccordionProps<RootComponentType>,
    ref: ForwardedRef<HTMLDivElement>,
  ) {
    const {
      children: childrenProp,
      defaultExpanded = false,
      expanded: expandedProp,
      onChange,
      slots,
      ...other
    } = props

    const [expanded, setExpandedState] = useControlled({
      controlled: expandedProp,
      default: defaultExpanded,
      name: 'Accordion',
      state: 'expanded',
    })

    const contextValue = useMemo(() => {
      const toggle = (event: SyntheticEvent): void => {
        setExpandedState(!expanded)

        if (onChange) {
          onChange(event, !expanded)
        }
      }

      return { expanded, toggle }
    }, [expanded, onChange, setExpandedState])

    const [summary, ...children] = Children.toArray(childrenProp)

    const { collapsed, transitionProps } = useCollapsed(expanded)

    const Root = slots?.root ?? 'div'
    const rootProps = useSlotProps({
      additionalProps: {
        ref,
      },
      className: 'accordion',
      elementType: Root,
      externalForwardedProps: other,
      externalSlotProps: undefined,
      ownerState: undefined,
    })

    return (
      <Root {...rootProps}>
        <AccordionContext.Provider value={contextValue}>
          {summary}
        </AccordionContext.Provider>
        <div
          className={clsx(
            'accordion__transition',
            collapsed && 'accordion__transition_collapsed',
          )}
          {...transitionProps}
        >
          {children}
        </div>
      </Root>
    )
  },
) as PolymorphicComponent<AccordionTypeMap>
