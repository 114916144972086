export const COOKIE_AD_SERVER_BROWSER_ID_CONVERSION_NAME = 'asbidc'
export const COOKIE_AD_SERVER_BROWSER_ID_TARGETING_NAME = 'asbidt'
export const COOKIE_AD_SERVER_CAMPAIGN_CODE_NAME = 'as-campaign'
export const COOKIE_AD_SERVER_USER_ID_NAME = 'asuid'

export const COOKIE_AUTH_NAME = 'auth'

export const COOKIE_CMS_H1_STORYBLOK_PREVIEW_NAME = 'h1StoryblokPreview'

export const COOKIE_ECONDA_VISITOR_ID_NAME = 'emos_jcvid'

export const COOKIE_FEATURE_FLAG_NAME = 'feature-flag-cookie'

export const COOKIE_FIRST_IMPRESSION_NAME = 'first-impression'

/**
 * This cookie is used to store the email of the guest user
 * that is trying to log in. This is used to prefill the email
 * field in the login form.
 */
export const COOKIE_GUEST_EMAIL_NAME = 'guest-email'

export const COOKIE_RX_CAMPAIGN_CODE_NAME = 'emos_jckamp'
