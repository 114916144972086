'use client'

import {
  Button as MuiButton,
  type ButtonOwnProps as MuiButtonOwnProps,
  type PolymorphicComponent,
  type PolymorphicProps,
} from '@mui/base'
import { clsx } from 'clsx'
import {
  type ComponentProps,
  type ElementType,
  type ForwardedRef,
  forwardRef,
  type ReactElement,
  useEffect,
} from 'react'

export type ButtonOwnProps = Omit<
  MuiButtonOwnProps,
  'href' | 'rootElementName' | 'to' | 'type'
> & {
  /**
   * The color of the component
   * @default primary
   */
  color?: 'primary' | 'secondary'

  /**
   * Icon placed after the children
   */
  endIcon?: ReactElement<ComponentProps<'svg'>, 'svg'> | false | null

  /**
   * The size of the component.
   * @default large
   */
  size?: 'large' | 'medium' | 'small'

  /**
   * Icon placed before the children
   */
  startIcon?: ReactElement<ComponentProps<'svg'>, 'svg'> | false | null

  /**
   * The variant to use.
   * @default filled
   */
  variant?: 'filled' | 'outlined' | 'text'
}

export interface ButtonTypeMap<
  RootComponentType extends ElementType = 'button',
> {
  defaultComponent: RootComponentType
  props: ButtonOwnProps
}

export type ButtonProps<
  RootComponentType extends ElementType = ButtonTypeMap['defaultComponent'],
> = PolymorphicProps<ButtonTypeMap<RootComponentType>, RootComponentType>

/**
 * Buttons allow users to take actions, and make choices, with a single tap
 */
export const Button = forwardRef(function Button<
  RootComponentType extends ElementType,
>(props: ButtonProps<RootComponentType>, ref: ForwardedRef<HTMLButtonElement>) {
  const {
    children,
    className,
    color = 'primary',
    endIcon,
    size = 'large',
    startIcon,
    variant = 'filled',
    ...other
  } = props

  useEffect(() => {
    if (
      process.env.NODE_ENV !== 'production' &&
      variant === 'text' &&
      !endIcon &&
      !startIcon
    ) {
      console.warn(
        'Variant "text" should have an icon, otherwise might be a regular link',
      )
    }
  }, [endIcon, startIcon, variant])

  useEffect(() => {
    if (
      process.env.NODE_ENV !== 'production' &&
      variant === 'outlined' &&
      color === 'secondary'
    ) {
      console.warn(
        'Variant "outlined" doesn\'t support "secondary" color combination, fallback to "primary" instead',
      )
    }
  }, [color, variant])

  return (
    <MuiButton
      className={clsx(
        'button',
        variant === 'filled' && 'button_filled',
        size === 'large' && 'button_large',
        size === 'medium' && 'button_medium',
        variant === 'outlined' && 'button_outlined',
        color === 'primary' && 'button_primary',
        color === 'secondary' && 'button_secondary',
        size === 'small' && 'button_small',
        variant === 'text' && 'button_text',
        className,
      )}
      ref={ref}
      {...other}
    >
      {startIcon ? (
        <span className="button__icon button__icon_start">{startIcon}</span>
      ) : null}
      {children}
      {endIcon ? (
        <span className="button__icon button__icon_end">{endIcon}</span>
      ) : null}
    </MuiButton>
  )
}) as PolymorphicComponent<ButtonTypeMap>
