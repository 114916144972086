import { clsx } from 'clsx'
import { type ComponentProps, forwardRef } from 'react'

type HeadingOwnProps = {
  /**
   * Sets heading level, h1 through h6
   */
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- possible heading element levels from https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Heading_Elements
  level: 1 | 2 | 3 | 4 | 5 | 6

  /**
   * The size of the component. The size is responsive, meaning it adjusts for `mobile` and `desktop` screens.
   *
   * Note: The heading font size on mobile has a limit, so `s` and `m` appear the same on `mobile` screens.
   */
  size: 's' | 'm' | 'l'
}

type HeadingProps = ComponentProps<`h${HeadingOwnProps['level']}`> &
  HeadingOwnProps

/**
 * Heading is used to render semantic HTML heading elements
 */
export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  function Heading(props, ref) {
    const { className, level, size, ...rest } = props

    const Component = `h${level}` as const

    return (
      <Component
        className={clsx(
          'heading',
          size === 's' && 'heading--size-s',
          size === 'm' && 'heading--size-m',
          size === 'l' && 'heading--size-l',
          className,
        )}
        ref={ref}
        {...rest}
      />
    )
  },
)
