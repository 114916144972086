import { clsx } from 'clsx'
import { type ComponentPropsWithoutRef, forwardRef } from 'react'

/**
 * The AccordionStack component manages layout of immediate children along the vertical axe
 */
export const AccordionStack = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<'div'>
>(function AccordionStack(props, ref) {
  const { className, ...rest } = props

  return (
    <div {...rest} className={clsx('accordion-stack', className)} ref={ref} />
  )
})
