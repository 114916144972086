export enum SHOP_ENV {
  PROD = 'prod',
  QS = 'qs',
}

export enum TENANT {
  AT = 'at',
  BE = 'be',
  CH = 'ch',
  COM = 'com',
  FR = 'fr',
  IT = 'it',
}

export enum TENANT_AND_ENV {
  AT = 'at',
  AT_QS = 'at-qs',
  BE = 'be',
  BE_QS = 'be-qs',
  CH = 'ch',
  CH_QS = 'ch-qs',
  COM = 'com',
  COM_QS = `com-qs`,
  FR = 'fr',
  FR_QS = 'fr-qs',
  IT = 'it',
  IT_QS = 'it-qs',
}

export interface TenantData {
  shopEnv: SHOP_ENV
  tenant: TENANT
  tenantAndEnv: TENANT_AND_ENV
}

export const tenantToLocaleRegionMap = {
  [TENANT.AT]: 'AT',
  [TENANT.BE]: 'BE',
  [TENANT.CH]: 'CH',
  [TENANT.COM]: 'DE',
  [TENANT.FR]: 'FR',
  [TENANT.IT]: 'IT',
} as const

export const tenantToVatPrefix = {
  [TENANT.AT]: 'ATU',
  [TENANT.BE]: 'BE',
  [TENANT.CH]: 'CHE',
  [TENANT.COM]: 'DE',
  [TENANT.FR]: 'FR',
  [TENANT.IT]: 'IT',
}
