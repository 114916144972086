import {
  type PolymorphicComponent,
  type PolymorphicProps,
  useSlotProps,
} from '@mui/base'
import {
  type ComponentPropsWithRef,
  type ElementType,
  type ForwardedRef,
  forwardRef,
} from 'react'

import { CounterIndicator } from '../CounterIndicator'

export type NumberedListItemOwnProps = {
  slotProps?: {
    content?: ComponentPropsWithRef<'div'>
    counter?: ComponentPropsWithRef<typeof CounterIndicator>
    root?: ComponentPropsWithRef<'li'>
  }
  slots?: {
    content?: ElementType
    root?: ElementType
  }
}

interface NumberedListItemTypeMap<
  RootComponentType extends ElementType = 'li',
> {
  defaultComponent: RootComponentType
  props: NumberedListItemOwnProps
}

type NumberedListItemProps<
  RootComponentType extends
    ElementType = NumberedListItemTypeMap['defaultComponent'],
> = PolymorphicProps<
  NumberedListItemTypeMap<RootComponentType>,
  RootComponentType
>

/**
 * The `NumberedListItem` is used to represent an item in a list. It must be contained in a parent ordered list (`<ol>`) element.
 * In ordered lists, they are displayed with an ascending customizable number counter (`CounterIndicator`) on the left.
 *
 * `CounterIndicator` customization is allowed through `slotProps.counter` property.
 */
export const NumberedListItem = forwardRef(function NumberedListItem(
  props: NumberedListItemProps,
  ref: ForwardedRef<HTMLLIElement>,
) {
  const { children, slotProps, slots, ...rest } = props

  const Root = slots?.root ?? 'li'
  const rootProps = useSlotProps({
    additionalProps: {
      ref,
    },
    className: 'numbered-list-item',
    elementType: Root,
    externalForwardedProps: rest,
    externalSlotProps: undefined,
    ownerState: undefined,
  })

  const Content = slots?.content ?? 'div'
  const contentProps = useSlotProps({
    className: 'numbered-list-item__content',
    elementType: Content,
    externalSlotProps: slotProps?.content,
    ownerState: undefined,
  })

  const counterProps = useSlotProps({
    className: 'numbered-list-item__counter',
    elementType: undefined,
    externalSlotProps: slotProps?.counter,
    ownerState: undefined,
  })

  return (
    <Root {...rootProps}>
      <CounterIndicator {...counterProps} />
      <Content {...contentProps}>{children}</Content>
    </Root>
  )
}) as PolymorphicComponent<NumberedListItemTypeMap>
